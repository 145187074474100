<template>
    <form @submit.prevent="submit" class="styled-form">

      <!-- Country -->
      <div v-if="defaultCountry == null" class="form-row">
        <label for="country">{{ $t("Select your country") }}</label>
        <div class="form-element" :class="this.$store.state.countries.length === 0 ? 'loading' : ''">
          <select-custom name="country" :selectedValue="$v.country.$model" @valueChange="$v.country.$model = $event" :search="false" :options="getSelectOptions(this.$store.state.countries, true)"></select-custom>
        </div>
        <span class="error" v-if="$v.country.$dirty && !$v.country.required">{{ $t("Field is required") }}</span>
        <span class="error" v-if="formErrorCountry">{{ $t("This country is not supported") }}</span>
      </div>

       <!-- Retailer -->
      <div v-if="country && defaultRetailer == null" class="form-row">
        <label for="retailer">{{ $t("Select a retailer") }}</label>
        <div class="form-element" :class="this.$store.state.retailers.length === 0 ? 'loading' : ''">
          <select-custom name="retailer" :selectedValue="$v.retailer.$model" @valueChange="$v.retailer.$model = $event" :search="false" :options="getSelectOptions(this.$store.state.retailers, false)"></select-custom>
        </div>
        <span class="error" v-if="$v.retailer.$dirty && !$v.retailer.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Store -->
      <div v-if="retailer" class="form-row">
        <label for="store">{{ $t("Select a store") }}</label>
        <div class="form-element" :class="this.$store.state.stores.length === 0 ? 'loading' : ''">
          <select-custom name="store" :selectedValue="$v.store.$model" @valueChange="$v.store.$model = $event" :search="true" :options="getSelectOptions(this.$store.state.stores, false)" placeholderSearch="City, zip code, street name..."></select-custom>
        </div>
        <span class="error" v-if="$v.store.$dirty && !$v.store.required">{{ $t("Field is required") }}</span>
      </div>

       <!-- Brands -->
      <div v-if="store || store === 0" class="form-row">
        <label for="brand">{{ $t("Select a brand") }}</label>
        <div class="form-element" :class="this.$store.state.brands.length === 0 ? 'loading' : ''">
          <select-custom name="brand" :selectedValue="$v.brand.$model" @valueChange="$v.brand.$model = $event" :search="false" :options="getSelectOptions(this.$store.state.brands, false)"></select-custom>
        </div>
        <span class="error" v-if="$v.brand.$dirty && !$v.brand.required">{{ $t("Field is required") }}</span>
      </div>

       <!-- Product -->
      <div v-if="brand" class="form-row">
        <label for="product">{{ $t("Select your product") }}</label>
        <div class="form-element" :class="this.$store.state.products.length === 0 ? 'loading' : ''">
          <select-custom name="product" :selectedValue="$v.product.$model" @valueChange="$v.product.$model = $event" :search="true" :options="getSelectOptions(this.$store.state.products, true)"></select-custom>
        </div>
        <span class="error" v-if="$v.product.$dirty && !$v.product.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Email -->
      <div v-if="product" class="form-row">
        <label for="email">{{ $t("What's your email address?") }}</label>
        <input v-model="$v.email.$model" :disabled="formLoading" type="email" name="email" id="email">
        <span class="error" v-if="$v.email.$dirty && !$v.email.required">{{ $t("Field is required") }}</span>
        <span class="error" v-else-if="$v.email.$dirty && !$v.email.email">{{ $t("This is not a correct email address") }}</span>
      </div>

      <!-- Email confirm -->
      <div v-if="product" class="form-row">
        <label for="emailConfirm">{{ $t("Confirm your email address") }}</label>
        <input v-model="$v.emailConfirm.$model" :disabled="formLoading" type="email" name="emailConfirm" id="emailConfirm">
         <span class="error" v-if="$v.emailConfirm.$dirty && !$v.emailConfirm.required">{{ $t("Field is required") }}</span>
        <span class="error" v-else-if="$v.emailConfirm.$dirty && !$v.emailConfirm.email">{{ $t("This is not a correct email address") }}</span>
        <span class="error" v-else-if="$v.emailConfirm.$dirty && !$v.emailConfirm.sameAsEmail">{{ $t("Email address not the same") }}</span>
      </div>

      <!-- Subject -->
      <div v-if="product" class="form-row">
        <label for="subject">{{ $t("What's the subject?") }}</label>
        <div class="form-element" :class="this.$store.state.subjects.length === 0 ? 'loading' : ''">
          <select-custom name="subject" :selectedValue="$v.subject.$model" @valueChange="$v.subject.$model = $event" :search="true" :options="getSelectOptions(this.$store.state.subjects, true)"></select-custom>
        </div>
        <span class="error" v-if="$v.subject.$dirty && !$v.subject.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Problem -->
      <div v-if="product" class="form-row">
        <label for="description">{{ $t("Describe the problem") }}</label>
        <textarea v-model="$v.description.$model" :disabled="formLoading" name="description" id="description"></textarea>
        <span class="error" v-if="$v.description.$dirty && !$v.description.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Receipt (file) -->
      <div v-if="product && !this.$store.state.portalsettings.retailer" class="form-row upload-img">
        <div v-if="!receiptFile">
          <label>{{ $t("Proof of purchase") }}</label>
          <label for="receipt-file" class="btn btn-primary small">{{ $t("Upload image") }}</label>
          <input @change="onReceiptFileChange($event)" :disabled="formLoading" type="file" accept="image/png, image/jpeg" name="receipt-file" id="receipt-file" />
        </div>
        <div v-else>
          <label>{{ $t("Proof of purchase") }}</label>
          <div class="preview-img">
            <img :src="receiptFilePreview" />
            <button @click="receiptFile = null; receiptFilePreview = null" :disabled="formLoading" class="btn btn-primary"></button>
          </div>
        </div>
        <span class="error" v-if="!$v.receiptFile.maxSize">{{ $t("This file is too large to upload. Max file size 9MB") }}</span>
      </div>

      <!-- Product (file) -->
      <div v-if="product && !this.$store.state.portalsettings.retailer" class="form-row upload-img">
        <div v-if="!productFile">
          <label>{{ $t("Upload a picture of the product and/or a picture of the problem") }}</label>
          <label for="product-file" class="btn btn-primary small">{{ $t("Upload image") }}</label>
          <input @change="onProductFileChange($event)" type="file" :disabled="formLoading" accept="image/png, image/jpeg" name="product-file" id="product-file" />
        </div>
        <div v-else>
          <label>{{ $t("Upload a picture of the product and/or a picture of the problem") }}</label>
          <div class="preview-img">
            <img :src="productFilePreview" />
            <button @click="productFile = null; productFilePreview = null" :disabled="formLoading" class="btn btn-primary"></button>
          </div>
        </div>
        <span class="error" v-if="$v.productFile.$dirty && !$v.productFile.required">{{ $t("Field is required") }}</span>
        <span class="error" v-if="!$v.productFile.maxSize">{{ $t("This file is too large to upload. Max file size 9MB") }}</span>
      </div>

      <div class="form-row"></div>

       <!-- Approved -->
      <div v-if="product && this.$store.state.portalsettings.retailer" class="form-row checkbox">
        <input v-model="$v.approved.$model" type="checkbox" :disabled="formLoading" name="approved" id="approved">
        <label for="approved">{{ $t("I hereby declare that I've seen the receipt and defect product and approve that the client needs a refund or replacement proposal.") }}</label>
        <span class="error" v-if="$v.approved.$dirty && !$v.approved.required">{{ $t("Field is required") }}</span>
      </div>

       <!-- GDPR -->
      <div v-if="product" class="form-row checkbox">
        <input v-model="$v.gdprConsent.$model" type="checkbox" :disabled="formLoading" name="gdpr" id="gdpr">
        <label for="gdpr">{{ $t("I have read and understood the") }} <router-link :to="{name: 'Privacy'}" target="_blank">{{ $t("Privacy Statement") }}</router-link> {{ $t("and I agree to the") }} <router-link :to="{name: 'TermsOfUse'}" target="_blank">{{ $t("Terms of Use") }}</router-link></label>
        <span class="error" v-if="$v.gdprConsent.$dirty && !$v.gdprConsent.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Recaptcha -->
      <div v-if="product" class="form-row">
         <vue-recaptcha sitekey="6LeORZccAAAAAO-S_MMoHiHOjfD5pS2G2uPUDYzo" ref="recaptcha" @verify="onVerifyRecaptcha" @expired="recaptcha = ''"></vue-recaptcha>
         <span class="error" v-if="$v.recaptcha.$dirty && !$v.recaptcha.required">{{ $t("Field is required") }}</span>
      </div>

      <div class="form-row" v-if="formErrors.length > 0 && !$v.$anyDirty">
        <span class="error" v-for="error in formErrors" :key="error.description">{{ $t(error.description) }}</span>
      </div>

      <div v-if="product" class="form-row">
        <input type="submit" :disabled="formLoading" class="btn btn-primary" :class="formLoading?'loading':''" :value="$t('Submit form')">
      </div>

      <popup v-if="popup.show" :title="popup.title" :text="popup.text" :button="popup.button" @showPopup="popup.show = $event"></popup>
    </form>
</template>

<script>
import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators'
import ApiService from '@/services/api.service'
import router from '@/router/'

import VueRecaptcha from 'vue-recaptcha'

// components
import Popup from '@/components/Popup.vue'
import SelectCustom from '@/components/Select.vue'

export default {
  name: 'CreateTicketForm',
  props: {
    defaultCountry: Number,
    defaultRetailer: Number
  },
  components: {
    Popup,
    SelectCustom,
    VueRecaptcha
  },
  data () {
    return {
      country: null,
      retailer: null,
      store: null,
      brand: null,
      product: null,
      email: '',
      emailConfirm: '',
      subject: null,
      description: '',
      approved: false,
      receiptFile: null,
      receiptFilePreview: null,
      productFile: null,
      productFilePreview: null,
      recaptcha: '',
      gdprConsent: false,
      formLoading: false,
      formErrors: [],
      formErrorCountry: false,
      popup: {
        show: false,
        title: '',
        text: '',
        button: {
          text: '',
          link: '',
          target: null
        }
      }
    }
  },
  validations: {
    country: { required },
    retailer: { required },
    store: { required },
    brand: { required },
    product: { required },
    email: { required, email },
    emailConfirm: {
      required,
      email,
      sameAsEmail: sameAs('email')
    },
    subject: { required },
    description: { required },
    recaptcha: { required },
    gdprConsent: {
      required: function (value) {
        if (value) {
          return true
        } else {
          return false
        }
      }
    },
    approved: {
      required: function (value) {
        if (!this.$store.state.portalsettings.retailer && !value) {
          return true
        } else if (value) {
          return true
        } else {
          return false
        }
      }
    },
    receiptFile: {
      maxSize (value) {
        if (value === null) return true
        // max 9mb
        if (value.size > 9437184) { return false } else { return true }
      }
    },
    productFile: {
      required: requiredIf(function () {
        return !this.$store.state.portalsettings.retailer
      }),
      maxSize (value) {
        if (value === null) return true
        // max 9mb
        if (value.size > 9437184) { return false } else { return true }
      }
    }
  },
  beforeCreate () {
    this.$store.commit('getCountries')
  },
  mounted () {
    this.checkDefaultCountry()
  },
  computed: {
  },
  methods: {
    onVerifyRecaptcha: function (response) {
      this.recaptcha = response
    },
    onReceiptFileChange (e) {
      this.receiptFile = null
      this.receiptFilePreview = null

      var files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      if (!(files[0].type === 'image/png' || files[0].type === 'image/jpeg' || files[0].type === 'image/jpg')) { return }

      this.receiptFile = files[0]
      this.createReceiptFile(files[0])
    },
    createReceiptFile (file) {
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.receiptFilePreview = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onProductFileChange (e) {
      this.productFile = null
      this.productFilePreview = null

      var files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      if (!(files[0].type === 'image/png' || files[0].type === 'image/jpeg' || files[0].type === 'image/jpg')) { return }

      this.productFile = files[0]
      this.createProductFile(files[0])
    },
    createProductFile (file) {
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.productFilePreview = e.target.result
      }
      reader.readAsDataURL(file)
    },
    checkDefaultCountry () {
      if (this.defaultCountry) {
        this.country = this.defaultCountry
      }
    },
    checkDefaultRetailer () {
      if (this.defaultRetailer) {
        this.retailer = this.defaultRetailer
      }
    },
    submit () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        ApiService.post('/Recaptcha', {
          token: this.recaptcha
        })
          .then(response => {
            if (response.data.success && response.data.data.result.valid) {
              this.createTicket()
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
      }
    },
    createTicket () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        const formData = new FormData()

        formData.append('priority', 1)
        formData.append('status', 2)
        formData.append('languageId', this.$store.state.language.id)
        formData.append('countryId', this.country)
        formData.append('retailerId', this.retailer)
        formData.append('storeId', this.store)
        formData.append('brandId', this.brand)
        formData.append('productId', this.product)
        formData.append('email', this.email)
        formData.append('subjectId', this.subject)
        formData.append('description', this.description)
        formData.append('approved', this.approved)
        formData.append('receiptFile', this.receiptFile)
        formData.append('productFile', this.productFile)
        formData.append('gdprConsent', this.gdprConsent)

        ApiService.postFile('/Tickets', formData)
          .then(response => {
            if (response.data.success) {
              this.country = null
              this.email = ''
              this.description = ''
              this.approved = false
              this.receiptFile = null
              this.receiptFilePreview = null
              this.productFile = null
              this.productFilePreview = null
              this.$v.$reset()
              if (this.$store.state.portalsettings.widget) {
                router.push({ name: 'Widget-CreateTicket-Success', params: { ticketId: response.data.data.id } })
              } else if (this.$store.state.portalsettings.retailer) {
                router.push({ name: 'Retailer-CreateTicket-Success', params: { ticketId: response.data.data.id } })
              } else {
                router.push({ name: 'Portal-CreateTicket-Success', params: { ticketId: response.data.data.id } })
              }
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
        this.$refs.recaptcha.reset()
      }
    },
    getSelectOptions (options, translate) {
      const optionsNew = []
      options.forEach(option => {
        optionsNew.push({
          value: option.id,
          text: translate ? this.$t(option.name) : option.name
        })
      })
      return optionsNew
    }
  },
  watch: {
    defaultCountry () {
      this.checkDefaultCountry()
    },
    defaultRetailer () {
      this.checkDefaultRetailer()
    },
    country () {
      if (this.country != null) {
        this.formErrorCountry = false
        // var countryId = this.country
        // if (this.$store.state.countries.find(function (item) { return item.id === countryId }).hasProgramme) {
        this.$store.commit('setCountry', this.country)
        this.$store.commit('getRetailers')
        // } else {
        //   this.country = null
        //   this.formErrorCountry = true
        // }
      }
      this.retailer = null
      this.checkDefaultRetailer()
      this.$v.$reset()
    },
    retailer  () {
      if (this.retailer != null) {
        this.$store.commit('setRetailer', this.retailer)
        this.$store.commit('getStores')
      } else {
        this.$store.commit('clearRetailers')
      }
      this.store = null
      this.$v.$reset()
    },
    store () {
      if (this.store != null) {
        this.$store.commit('setStore', this.store)
        this.$store.commit('getBrands')
      } else {
        this.$store.commit('clearStores')
      }
      this.brand = null
      this.$v.$reset()
    },
    brand () {
      if (this.brand != null) {
        this.$store.commit('setBrand', this.brand)
        this.$store.commit('getProducts')
      } else {
        this.$store.commit('clearBrands')
      }
      this.product = null
      this.$v.$reset()
    },
    product () {
      if (this.product != null) {
        var productId = this.product
        var selectedProduct = this.$store.state.products.find(function (item) { return item.id === productId })
        if (selectedProduct.guarantee) {
          this.$store.commit('setProduct', this.product)
          this.$store.commit('getSubjects')
        } else {
          this.product = null
          this.popup.text = 'This product warranty has expired. You are unable to create a ticket. For more questions, please consult our FAQ'
          this.popup.button.link = 'https://brandloyalty-customersupport.freshdesk.com/en/support/solutions'
          this.popup.button.text = 'view FAQ'
          this.popup.show = true
        }
      } else if (!this.popup.show) {
        this.$store.commit('clearProducts')
      }
      this.subject = null
      this.$v.$reset()
    },
    subject () {
      if (this.subject != null) {
        this.$store.commit('setSubject', this.subject)
      } else {
        this.$store.commit('clearSubjects')
      }
    }
  }
}
</script>
