<template>
  <div :id="'customSelect_' + name" class="selectContainer" :class="{'active' : dropdownActive}">
    <div class="select" @click="dropdownActive = !dropdownActive">
      <span>{{select.text}}</span>
    </div>

    <div class="selectDropdown">
      <div class="dropdownInner">
        <div class="search" v-if="search">
          <input type="text" v-model="searchString" :placeholder="placeholderSearch == '' || placeholderSearch == null ? $t('Search...') : placeholderSearch" autocomplete="off">
        </div>
        <div class="options">
          <span v-for="option in optionsFiltered" :key="option.value" @click="selectOption(option)" class="option" :class="{ 'selected' : select.value == option.value}">{{ option.text }}</span>
          <span v-if="search && optionsFiltered.length == 0" class="noResults">{{ $t("No results found") }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Select',
  props: {
    name: String,
    selectedValue: [String, Number],
    options: Array,
    search: Boolean,
    placeholderSearch: String,
    translateText: Boolean
  },
  data () {
    return {
      optionsFiltered: [],
      select: {
        value: '',
        text: ''
      },
      searchString: '',
      dropdownActive: false
    }
  },
  beforeCreate () {

  },
  mounted () {
    const vm = this
    vm.optionsFiltered = vm.options
    vm.select.value = vm.selectedValue

    var ignoreClickOnMeElement = document.getElementById('customSelect_' + vm.name)
    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target)
      if (!isClickInsideElement) {
        vm.dropdownActive = false
      }
    })
  },
  computed: {},
  methods: {
    filterOptions: function () {
      const vm = this

      vm.optionsFiltered = vm.options.filter(function (option) {
        return (
          cleanWords(option.text).indexOf(
            cleanWords(vm.searchString)
          ) !== -1
        )
      })

      const selectedOption = vm.options.filter(function (option) {
        return option.value === vm.select.value
      })

      if (selectedOption.length > 0) {
        vm.select = selectedOption[0]
      } else {
        vm.select = {
          value: '',
          text: ''
        }
      }

      function cleanWords (word) {
        if (word === undefined) {
          return ''
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase()
      }
    },
    selectOption: function (option) {
      this.select = option
      this.dropdownActive = false

      this.$emit('valueChange', this.select.value)
    }
  },
  watch: {
    options () {
      this.filterOptions()
    },
    searchString () {
      this.filterOptions()
    }
  }
}
</script>
