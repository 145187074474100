<template>
    <div class="popup">
      <div class="popup-background"></div>
      <div class="popup-wrapper">
        <div class="popup-inner">
          <h2 v-if="title" class="popup-title">{{ $t(title) }}</h2>
          <h4 v-if="text">{{ $t(text) }}</h4>
          <a v-if="button.link && button.text" :href="button.link" :target="button.target" class="btn btn-primary">{{ $t(button.text) }}</a>

          <span class="popup-close" @click="closePopup">X</span>
        </div>
       </div>
    </div>
</template>

<script>

export default {
  name: 'Popup',
  props: {
    title: String,
    text: String,
    button: Object
  },
  data () {
    return {}
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {
    closePopup: function () {
      this.$emit('showPopup', false)
    }
  },
  watch: {}
}
</script>
